import { Controller } from "@hotwired/stimulus";
import * as animate from "../../helpers/animate";

export default class extends Controller {
  static targets = [
    "customEwPierSpanFieldContainer",
    "rowsField",
    "diagonalBracingCheckbox",
    "maxSpanDisplay",
    "railLengthChoiceContainer",
  ];

  static values = {
    rowOptionsToLookupRows: Object,
  };

  ewPierSpanTypeChange(event) {
    const checkbox = event.currentTarget;
    const { value } = checkbox;
    const { checked } = checkbox;
    const customChecked = (value === "custom" && checked) || (value === "max" && !checked);
    animate.toggle(this.customEwPierSpanFieldContainerTarget, customChecked);
  }

  updateMaxSpan = (_event) => {
    if (this.hasDiagonalBracingCheckboxTarget) {
      const rows = this.rowsForMaxSpanLookup;

      const diagonalBracing = this.diagonalBracingCheckboxTarget.checked;
      const maxSpanLookup = this.projectMaxSpans.find(
        (pms) => pms.rows === rows && pms.diagonal_bracing === diagonalBracing,
      );
      const maxSpan = maxSpanLookup !== undefined ? maxSpanLookup.max_span : "?";
      this.maxSpanDisplayTarget.innerHTML = maxSpan;
    }
  };

  get rowsForMaxSpanLookup() {
    const rows = Number.parseInt(this.rowsFieldTarget.value);
    return this.rowOptionsToLookupRowsValue[rows];
  }

  get projectMaxSpans() {
    return this.subArraysController.projectMaxSpans;
  }

  get subArraysController() {
    const identifier = "gb--sub-arrays";
    const controllerElement = document.querySelector(`[data-controller='${identifier}']`);
    return this.application.getControllerForElementAndIdentifier(controllerElement, identifier);
  }
}
